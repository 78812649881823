<template>
    <div class="main-container">
        <MHeader
            :headerMenus="headerMenus"
            @changeModulePage="changeModulePage($event, 'clickHeader')"
            @layout="layout"
            @changeTheme="changeTheme"
        />
        <SideBar
            v-show="showSideBar"
            :barColor="barColor"
            :headerColor="headerColor"
            :textColor="textColor"
            :barSelectedColor="barSelectedColor"
            :hoverBackgroundColor="hoverBackgroundColor"
            :barPullDownColor="barPullDownColor"
            :user_routers="menus_routers"
            :navBarWidth="navBarWidth"
            :themeType="themeType"
            :sideBarWd="sideBarWd"
            :staticRouteMenu="staticRouteMenu"
            @navBarFolded="navBarFolded"
            @setPageInit="setPageInit"
        />
        <PageBoard
            ref="pageBoardWrapper"
            :showCrumb="showCrumb"
            :header-color="headerColor"
            :text-color="barColor"
            :navBarWidth="sideBarWd"
            @changeModulePage="changeModulePage"
            @setGlobalPageType="setGlobalPageType"
        />
        <toolkit v-if="toolbarRightShow" ref="J_system_toolkit"></toolkit>
        <theme-dialog
            v-if="showTheme"
            :showTheme="showTheme"
            @handleCloseThemeDialog="handleCloseThemeDialog"
        >
        </theme-dialog>
    </div>
</template>
<script>
import XkLayout from '@/views/XkLayout.vue';
import MHeader from "@/components/Header";
import SideBar from "@/components/SideBar";
import PageBoard from "@/components/PageBoard";
import Toolkit from "@/components/sub/Toolkit/index.vue";
import ThemeDialog from '@/components/sub/Theme/index.vue';

import staticRouteMenu from '@/router/staticRouterConfig.js';
import {removeToken, getToken} from "@/libs/auth";
import {treeFind} from "@/libs/utils";
import {LoginModel} from "@/models/Login.js";
import {mapState, mapGetters} from "vuex";
import { debounce } from "common-local";
export default {
    name: "MainContainer",
    components: {
        XkLayout,
        MHeader,
        SideBar,
        PageBoard,
        Toolkit,
        ThemeDialog
    },
    provide() {
        return {
            mainContainer: this
        }
    },
    data() {
        return {
            staticRouteMenu,
            templateBarShow: false,
            headerColor: "",
            barColor: "",
            barPullDownColor: "",
            barSelectedColor: "",
            hoverBackgroundColor: "",
            textColor: "#2972FB",
            template_info: [],
            user_routers: [], // 所有路由数据
            header_routers: [], // 头部路由
            menus_routers: [], //左侧路由
            layoutModification: false,
            menusRout: [],
            navBarWidth: "170px",
            sideBarWd: "190px",
            themeType: "enterprise",

            showSideBar: true,
            showCrumb: true,
            showTheme: false
        };
    },
    created() {
        window.addEventListener("storage", (e) => {
            if (e.key === "communicationPage" && e.newValue === "none") {
                console.log(e, "e");
                this.$router.push({name: "login"});
            }
        });
        this.$on('updateThemeFlag',()=>{
            this.showTheme = true
        })
        this.binWindowEvent();
    },
    computed: {
        ...mapState({
            hasAuth: (state) => state.hasAuth,
            templateInfo: (state) => state.templateInfo,
            userRouters: (state) => state.userRouters,
            style: (state) => state.style,
            toolbarRightShow: state => state.style.toolbarRightShow,
        }),
        ...mapGetters({
            mixTopRouter: 'mixTopRouter',
            mixSideRouter: 'mixSideRouter'
        }),
        headerMenus () {
            return this.mixTopRouter.filter(item => {
                return item.menuId !== '21';
            })
        }
    },
    methods: {
        /**
         * @Description: 获取页面布局及路由数据
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-07-11 14:19:42
         * @param {*} val
         */
        initGetPageLayout(val) {
            this.systemOperation()
            this.changeMenuCrumbShow(val);
            if (val.meta) {
                this.getSideMenuData(val.meta);
            }
        },
        /**
         * @Description: 系统操作
         * @Author: 武东超
         * @Date: 2024-09-06 10:41:24
         */        
        systemOperation() {
            this.$nextTick(() => {
                if (!this.toolbarRightShow) return;
                this.$refs.J_system_toolkit && this.$refs.J_system_toolkit.getNewFeedback();
            });
        },
        /**
         * @Description: 开关左侧菜单
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-07-11 14:49:39
         * @param {*} data
         */
        navBarFolded(type) {
            if (type) {
                this.sideBarWd = "60px";
            } else {
                this.sideBarWd = "190px";
            }
        },
        /**
         * @Description: 部分组件是否隐藏
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-07-13 15:54:47
         */
        changeMenuCrumbShow(val) {
            console.log(val, "组件部分隐藏显示切换");
            switch (val.name) {
                case "FirstPage":
                case "firstPage":
                case "firstPageHome":
                    this.showSideBar = false;
                    this.showCrumb = false;
                    this.sideBarWd = '0px';
                    break;
                case "NotificationList":
                    this.showSideBar = false;
                    this.showCrumb = false;
                    this.sideBarWd = '0px';
                    break;
                default:
                    this.showSideBar = true;
                    this.showCrumb = true;
                    this.navBarFolded(false)
                    break;
            }
        },
        /**
         * @Description: 退出
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-07-11 14:20:44
         */
         layout() {
            this.$confirm("确定要退出吗?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    this.handleLogout();
                })
                .catch(() => {});
        },
        handleLogout() {
            const token = getToken();
            if (token === undefined) {
            } else {
                const loginModel = new LoginModel();
                loginModel.logout({}).then((res) => {
                    if (res.data.code === "200") {
                        removeToken();
                        this.$store.state.hasAuth = false;
                        this.$router.push("/login");
                    } else {
                        this.$message.error(res.data.msg);
                    }
                });
            }
        },
        changeModulePage(data, type) {
            this.getSideMenuData(data);
            this.getSideMenuFirstRouter(type, data);
        },
        getSideMenuData (data) {
            const staticRouteMenuId = this.staticRouteMenu;

            if (this.mixSideRouter && this.mixSideRouter.length > 0) {
                const menuId = data.menuId || '';
                let curMenuInfo;

                var sideMenu = this.mixSideRouter.filter(item => {
                    return menuId === item.menuId;
                })[0];

                if (data && data.selMenuId) {
                    let statusRoutInfo = staticRouteMenuId.find((item) => { return item.menuId === data.selMenuId})
                    sideMenu = this.mixSideRouter.filter(item => {
                        return statusRoutInfo.pId === item.menuId;
                    })[0];
                }

                sideMenu ? sideMenu : curMenuInfo = this.mixTopRouter.filter(item => {
                    let menuList = item.children.map(item => item.menuId)
                    if (menuList.includes(data.menuId)) {
                        return item;
                    }
                })[0];

                if (curMenuInfo && curMenuInfo.menuId) {
                    sideMenu = this.mixSideRouter.filter(item => {
                        return curMenuInfo.menuId === item.menuId;
                    })[0];
                };

                this.menus_routers = (sideMenu && sideMenu.children && sideMenu.children.length > 0) ? sideMenu.children : [];
            }
        },
        getSideMenuFirstRouter (type, data) {
            const _this = this;
            // 找到第一个可以跳转的路由
            if (type === "clickHeader") {
                const menu = treeFind(
                    this.menus_routers,
                    (e) => !e.children && e.isFrame != "1"
                );

                if (menu) {
                    setTimeout(function(){
                        _this.$router.push({
                            name: menu.name,
                        })
                    });

                } else {
                    // 没有第一个路由，全是外链接
                    this.initGetPageLayout(data.name);
                    setTimeout(function(){
                        _this.$router.push({
                            name: data.name,
                        })
                    });

                }
                this.navBarWidth = "170px";
            }

        },
        /**
         * @Description: 二级页面去除面包屑
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-07-18 10:31:24
         */
         setGlobalPageType (type) {
            console.log(type,'setGlobalPageType')
            switch (type) {
                case 'form':
                case 'detail':
                    this.showCrumb = false;
                    break;
                default:
                    this.showCrumb = true;
                    break;
            }
         },
         setPageInit () {
            this.$eventBroadcast('setPageInitTabs')
        },
        /**
         * @Description: 打开主题
         * @Author: 武东超
         * @Date: 2024-08-20 17:07:13
         */
        changeTheme() {
            this.showTheme = true;
        },
        handleCloseThemeDialog() {
            this.showTheme = false;
        },
        binWindowEvent() {
            window.addEventListener('resize', this.handlerMenuResize, false)
        },
        handlerMenuResize:debounce(function(){
            let w = document.documentElement.clientWidth;
            if (w < 1440) {
                this.navBarFolded(true)
            };
            this.$eventBroadcast('globalResize')
        }, 200),
    },
    watch: {
        $route: {
            handler(newVal) {
                if (newVal.name) {
                    this.initGetPageLayout(newVal);
                }
            },
            immediate: true,
            deep: true,
        },
    },
};
</script>
<style lang="scss" scoped>
.main-container {
    width: 100%;
    height: 100%;
    position: relative;
    transition: background-color 0.3s;
}
</style>
